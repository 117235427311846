import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class TaxConsulting extends React.Component {
	render() {
		const siteTitle = 'Tax Consulting'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Tax Consulting</h3>
								<p>
									Through extensive technical knowledge and practical experience
									our taxation advisory services are available for both
									individual and corporate clients.
								</p>
								<p>
									At WSC Group we offer a full range of taxation services. Our
									proactive approach ensures we deliver a consistent service and
									build solid relationships which are constantly evolving.
								</p>
								<p>
									Taxation can be a major cost to your business. We will work in
									partnership with you to minimize your tax and help you achieve
									your key objectives.
								</p>
								<h4>
									We provide a complete service including preparation of returns
									and advice in the following areas:
								</h4>
								<ul>
									<li>Income Tax</li>
									<li>Goods and Services Tax (GST)</li>
									<li>Capital Gains Tax for individuals and small business</li>
									<li>Fringe Benefits Tax (FBT)</li>
									<li>Payroll Tax</li>
									<li>Workers Compensation</li>
									<li>Land Tax</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default TaxConsulting

export const TaxConsultingPageQuery = graphql`
	query TaxConsultingPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
